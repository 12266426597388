.redirect {
    margin: 0.5rem;
}

.container-add-as {
    display: flex;
    margin-top: 0rem !important;
    gap: 1rem;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
}

.attributes {
    border: 0.1rem solid rgb(179, 179, 179);
    width: 25rem;
    padding: 1rem;
    border-radius: 1rem !important;

    .countries {
        background: white;
        width: 100%;
        height: 10rem;
    }

    .select {
        margin-top: 1rem;
        width: fixed !important;
    }

    .cat {
        margin-bottom: 1rem;
    }

    .country {
        height: 10rem;
        overflow-y: scroll;
        border: solid 0.1rem rgb(158, 158, 158);
        border-radius: 0.3rem;
    }

    .country-search {
        border-radius: 0.6rem;
        padding: 0.4rem;
        outline: 0;
        border: 0;
        background: rgb(223, 223, 223);
        height: 30px;
        margin-right: 10px;
        width: 80%;
    }
}

.options {
    .tabs {
        border: 0.1rem solid rgb(179, 179, 179);
        border-radius: 1rem;
        margin-bottom: 20px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: auto; /* Adjust width to fit content */
        height: auto; /* Adjust height to fit content */
        box-sizing: border-box; /* Ensure padding and border are included in the size */
        align-items: flex-start; /* Align items to the start of the card */

        .tab-row {
            display: flex;
            align-items: center;
            gap: 2rem; /* Space between rows */
            width: 100%;

            .row-item {
                display: flex;
                flex-direction: column;
                gap: 0.5rem; /* Space between select and text field */
                flex: 1; /* Allow equal spacing for each row item */
            }

            .delete {
                display: flex;
                align-items: center;
                width: 2rem;
                height: 2rem;
                justify-content: center;
                cursor: pointer;
            }
        }
    }

    .switch {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-end;
    }

    .sub-input {
        width: 10rem;
    }

    .add-button {
        margin-top: 2rem;
        border-radius: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        .add-icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    .save {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        margin-top: 2rem;
    }
}

.dup-header {
    font-weight: 500;
    text-align: center;
}

.spinner-add {
    margin-top: 2rem;
    margin-left: 45vw !important;
}

.country-h {
    display: flex;
    justify-content: space-between;
}

.country-div {
    padding-left: 1rem;
    padding-top: 0.3rem;
}

.lds-text-field.disabled,
.lds-text-field:disabled {
    color: black !important;
}

.country-search {
    border-radius: 0.6rem;
    padding: 0.4rem;
    outline: 0;
    border: 0;
    background: rgb(223, 223, 223);
    height: 30px;
    margin-right: 10px;
    width: 80%;
}

.lds-select-options {
    z-index: 40 !important;
    width: fit-content !important;
}

@media screen and (min-width: 1100px) {
    .sub-input {
        width: 5rem;
    }
}
