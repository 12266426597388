.new_select{
    width: 200px;
}

.modal-header {
    position: relative;
    padding: 16px;
    text-align: right;
}

.close-btn {
    font-size: 20px;
    cursor: pointer;
}

.asterik-required{
    color: red;
    font-size: 1.3rem
}