.overview-hero {
    background: white !important;
}

.overview-container {
    display: flex;
    margin-left: 1rem;
}

.select-cl {
    display: flex;
    width: 10rem !important;
    justify-content: space-between;
}
.three-way-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f53426;
    border-radius: 20px;
    padding: 5px;
    width: 300px;
    height: 40px;
    position: relative;
    box-shadow: 0 2px 5px rgba(241, 3, 3, 0.1);
  }
  
  .switch-tab {
    flex: 1;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 20px;
  }
  
  .switch-tab.active {
    background-color: white;
    color: #333;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .switch-tab:hover {
    background-color: #534444;
  }
.col-div {
    display: flex;
    justify-content: center;
    gap: 0.3rem;
}

.parent-col-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;
    width: 1opx;

}

.select-all {
    width: 20rem;
}

.col-icon {
    width: 1.5rem !important;
}

.overview-text {
    font-size: 2rem;
}
.button-container-ov{
   margin-left: 3%  ;
}

.spaan {
    display: flex;
    flex-direction: column;
    padding-top: 0.6rem;
    border-top: 0.2rem solid #d52b1e;
}

.see-more {
    cursor: pointer;
}

.column-list {
    height: 12rem;
    width: 20rem;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    background: white;
    gap: 0.2rem;
    display: flex;
    flex-direction: column;
    margin-top: -2rem;
    margin-left: -5rem;
    // position: absolute;
    z-index: 10;
    border-radius: 0.8rem;
    border: 0.2rem solid #d52b1e;
    padding: 1rem;
}

.column-list-l {
    height: 12rem;
    width: 20rem;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    background: white;
    gap: 0.2rem;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-left: -5rem;
    position: absolute;
    z-index: 10;
    border-radius: 0.8rem;
    border: 0.2rem solid #d52b1e;
    padding: 1rem;
}

.label-col {
    margin-top: 0.5rem;

}

.buttons {
    height: 3rem;
    margin-right: 1rem !important;
}

.downloadcolor {
    background-color:#337700 !important;
    //border: none;
}

.downloadcolor:hover {
    background-color: #204b00 !important; /* Hover color */
    //border: none;
}

.table-ends {
    text-align: left !important;

}

.text-fld {
    margin-top: 2rem;
}

.attriDiv {
    display: flex;
    flex-direction: column;
    align-items: start;

    text-align: center;

}

.attriDivCore {
    display: flex;
}

.icon-col {
    display: flex;
    justify-content: center;
}

.icon-colum {
    width: 4rem !important;
    background: #d52b1e;
    fill: white;
    border-radius: 0.2rem;
}

.table-icons {
    width: 1.3rem !important;
    height: 1.3rem !important;
}

.overview-table {
    min-height: 20rem;
}

.ov-checkbox {
    margin-top: -1.5rem;
}

.overview-iconStyle {
    display: flex;
    align-items: initial;
    max-height: 1rem;
    margin-top: 0.3rem
}

.ov-icon-size {
    height: 15px;
    margin-bottom: -2rem;
    max-width: 2.5rem;

}

.ov-search {
    width: 12rem;
}

.ov-body {
    z-index: 1 !important;
}

.ov-spinner {
    z-index: 3 !important;
    position: absolute !important;
    margin-left: 48vw !important;
    margin-top: 3rem !important;
    height: 20rem !important;
}

.ov-end {
    display: flex;
    justify-content: center;
    margin: 1rem 0rem 1rem 0rem;
}

.rejected {
    background-color: rgb(241, 184, 184)
}

.table-click {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 600;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
}

// .down-icon{
//     width: ;
// }
.sub-header {
    display: flex;
    flex-direction: column;
    align-self: end;
    gap: 3rem;
    margin-right: 2rem;
}

.sub-icon {
    width: 9rem !important;
    margin-right: 0rem;
}

.new-metric-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.p-icon {
    width: 2rem !important;
    height: 2rem !important;
}

.sub-table-div {
    position: absolute;
    margin-left: 5vw;
    height: 42rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    background-color: aliceblue;
    z-index: 20;
    padding: 2rem 1rem 1rem 1rem;
    margin-top: 10rem;
    border: 0.2rem solid black !important;
}

// .lds-modal-body{
//     padding:6px !important

// }
.col-12 {
    width: 90vw !important;
}

.more-col {
    display: flex !important;
    justify-content: center;
}

.sub-table {
    max-height: 27rem !important;
 
    // overflow-x: hidden !important;
    border-radius: 1rem;
    z-index: 20;
    min-height: 20rem;
}
 
.lds-table-wrapper {
    position: relative;
    z-index: 20;
    overflow: auto !important;
    //min-width: 111%;
    max-width: 150%;
    // padding-top: 10px;
    padding: 30px;
    margin-top: 0rem;
}
.tooltip-description {
    position: relative;
    // flex: auto  !important;
    z-index: 9999 !important; /* Ensures the tooltip is on top of table content */
    // overflow: visible !important; /* Prevent clipping */
    // min-width: 25rem !important; /* Set a max-width to prevent it from getting too wide */
    // word-wrap: break-word;
    // overflow: visible !important;
    // max-width: 50rem;
    // padding: 30px;
    // margin-top: 5rem;
    // max-height: 14rem;
 
 
}

.parent-h {
    font-weight: 600;
}

.parent-container {
    margin-left: 3rem;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    position: relative;
    z-index: 10;

}

.parent-row {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.no-data-metric {
    position: absolute;
    margin-top: 5vw;
    margin-left: 30vw;
}
 
.paginationLastFirstBtn {
    color: #39759e !important;
    border: #39759e !important;
}

.alignTop{
    margin-top: 100px;
}